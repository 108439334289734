













import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BackButtonLayout from '@/layouts/management/BackButtonLayout.vue';
import UserFormTemplate from '@/components/users/UserFormTemplate.vue';
import { MultiUserFormRequest, UpdateUserForAxios } from '@/models/users';
import { UserStores } from '@/store/users';
import { DropdownItem } from '@/models/dropdown';
import { uploadUserProfilePicture } from '@/utils/attachments';
@Component({
  components: { Page, BackButtonLayout, UserFormTemplate },
})
export default class EditUsers extends Vue {
  userDetailStore = UserStores.detail;
  userStateStore = UserStores.state;
  titles: DropdownItem[] = [];

  currentFormData: UpdateUserForAxios = {
    id: Number(this.$route.params.id),
    title_id: -1,
    title: {
      id: -1,
      name: '',
    },
    firstname: ``,
    lastname: ``,
    email: ``,
    password: ``,
    force_update_password: false,
    mobile: ``,
    birthday: ``,
    role: ``,
    address: ``,
    address2: ``,
    city: ``,
    state: ``,
    postcode: ``,
    degree: ``,
    university: ``,
    gender: ``,
    profile_url: ``,
  };

  get layoutTitle() {
    return `Edit Current User`;
  }

  get backRoute() {
    return `/users`;
  }

  mounted() {
    this.userDetailStore.retrieveTitles();
    this.userDetailStore.retrieveUserById(Number(this.$route.params.id));
  }

  @Watch(`userDetailStore.fetchedUserResponse`)
  populateCurrentForm() {
    if (this.userDetailStore.fetchedUserResponse) {
      this.currentFormData = {
        ...this.userDetailStore.fetchedUserResponse,
      };
    }
  }

  updateCurrentUser(payload: MultiUserFormRequest) {
    this.userDetailStore.updateUserById(payload);
  }

  @Watch(`userDetailStore.response`)
  redirectToUserListing() {
    if (this.userDetailStore.response) {
      if (typeof this.userDetailStore.userData!.profile_url !== 'string' && this.userDetailStore.userData!.profile_url.originalFile) {
        uploadUserProfilePicture(this.userDetailStore.userData!.profile_url.originalFile , this.userDetailStore.userData!.email);
      }
      this.userStateStore.setIsModified(true);
      this.$router.push({
        path: `/users`,
      });
    }
  }

  @Watch(`userDetailStore.fetchedTitleResponse`)
  setTitles() {
    if (this.userDetailStore.fetchedTitleResponse) {
      this.titles = [];
      this.userDetailStore.fetchedTitleResponse.objects.forEach((item) => {
        this.titles.push({
          text: item.name,
          value: item.id.toString(),
          uniqueKey: `${item.name}_1`,
        });
      });
    }
  }
}
